angular.module("app")
  .directive("cSprayGraph", function() {
    return {
      restrict: "E",
      templateUrl: "w-spray-graph",
      scope: {
        danger: '<',
        zoomIn: '=',
        zoomOut: '='
      }
    }
  });